<template>
  <div class="box f_color1 font2">
    <van-nav-bar title="推广组信息" left-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <div v-if="info" class="content font2">
      <div class="listItem">
        <div class="item_left">推广组名称:</div>
        <div class="f_color3 item_right">
          <van-field v-model="name" type="text" readonly />
        </div>
      </div>
      <div class="listItem">
        <div class="item_left">组员有收益组长的分成比例:</div>
        <div class="f_color3 item_right">
          <van-field v-model="leaderRatio" type="text" readonly />
        </div>
      </div>
      <div class="listItem">
        <div class="item_left">组员有收益上级的分成比例:</div>
        <div class="f_color3 item_right">
          <van-field v-model="memberRatio" type="text" readonly />
        </div>
      </div>
      <div class="listItem">
        <div class="item_left">组员有充值组长的分成比例:</div>
        <div class="f_color3 item_right">
          <van-field v-model="memberPayOwnerRatio" type="text" readonly />
        </div>
      </div>
      <div class="listItem">
        <div class="item_left">组员有充值上级的分成比例:</div>
        <div class="f_color3 item_right">
          <van-field v-model="chargeIncomeRatio" type="text" readonly />
        </div>
      </div>


      <div class="listItem" v-if="memberIncomeRatio">
        <div class="item_left">女用户的收益比例:</div>
        <div class="f_color3 item_right">
          <van-field v-model="memberIncomeRatio" type="text" readonly />
        </div>
      </div>
      <div class="listItem">
        <div class="item_left">结算周期:</div>
        <div class="f_color3 item_right">
          <van-field v-model="period" type="text" readonly />
        </div>
      </div>
      <div class="listItem" @click="onMemberNumTapped">
        <div class="item_left">成员数:</div>
        <div class="f_color3 item_right" style="margin-left: 2%">
          <van-field v-model="memberNum" type="text" readonly />
        </div>
        <div class="arrow">
          <img src="@/assets/image/right.png" alt="" />
        </div>
      </div>
      <div class="listItem">
        <div class="item_left">创建时间:</div>
        <div class="f_color3 item_right" >
          <van-field v-model="createTime" type="text" readonly />
        </div>
      </div>
    </div>
    <div v-if="info" class="bottom">
      <van-button class="btn l_btn m_bottom2" type="success" round size="normal" @click="onCommissionTapped"
        color="#2369f6" text="分成统计" />
      <van-button class="btn m_bottom2" type="success" round size="normal" @click="onIncomeTapped" color="#f29a38"
        :text="info.type === 0 ? '收益流水' : '充值流水'" />
    </div>
    <Mask v-if="marke"></Mask>
  </div>
</template>
<script>
// import { wxqq } from '../utils/utils'
import Mask from '../components/Mask'
import routerUtil from '../utils/routerUtil'
import { Toast } from "vant"

export default {
  name: 'GroupInfo',
  data() {
    return {
      info: null,
      marke: false,
    }
  },
  computed: {
    name() {
      if (this.info) {
        return this.info.name;
      }
      return ""
    },
    leaderRatio() {
      if (this.info) {
        return this.info.ownerRatio * 100 + "%";
      }
      return ""
    },
    memberRatio() {
      if (this.info) {
        return this.info.memberRatio * 100 + "%";
      }
      return ""
    },

    memberPayOwnerRatio(){
      if (this.info) {
        return this.info.memberPayOwnerRatio * 100 + "%";
      }
      return ""
    },

    chargeIncomeRatio(){
      if (this.info) {
        return this.info.chargeIncomeRatio * 100 + "%";
      }
      return ""
    },

    memberIncomeRatio() {
      if (this.info && this.info.type === 0) {
        if (this.info.memberIncomeRatio) {
          return '消息' + this.info.memberIncomeRatio.message * 100 + '%，礼物' + this.info.memberIncomeRatio.gift * 100 + '%，通话' + this.info.memberIncomeRatio.call * 100 + '%'
        }
        return ''
      }
      return null
    },
    period() {
      if (this.info) {
        switch (this.info.period) {
          case 1:
            return "日";
          case 2:
            return "周";
          case 3:
            return "月";
        }
      }
      return ""
    },
    memberNum() {
      if (this.info) {
        return this.info.memberCount;
      }
      return ""
    },
    createTime() {
      if (this.info) {
        const date = new Date(this.info.createTime);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = date.getHours();
        const min = date.getMinutes();
        const sec = date.getSeconds();
        return `${date.getFullYear()}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day} ${hour > 9 ? hour : '0' + hour}:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
      }
      return ""
    }
  },
  components: {
    Mask,
  },
  mounted() {
    // this.marke = wxqq()
    this.reqInfo();
  },
  methods: {
    onClickLeft() {
      routerUtil.back()
    },
    onClickRight() {
    },
    reqInfo() {
      this.$request.postJson('/api/user/pay_group/info', {})
        .then((res) => {
          if (res.data.code == 0) {
            this.info = res.data.data;
            if (!this.info) {
              Toast('暂无推广组信息');
              routerUtil.back()
              return;
            }
          }
        });
    },
    onMemberNumTapped() {
      this.$router.push({ path: 'MemberList' });
    },
    onCommissionTapped() {
      this.$router.push({ path: 'Comission' });
    },
    onIncomeTapped() {
      this.$router.push({ path: 'Income' });
    }
  },
}
</script>
<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.content {
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;

  .listItem {
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;

    .item_left {
      width: 18%;
    }

    .item_right {
      flex-grow: 2;
    }

    .van-cell {
      height: 50px;
      padding-right: 0;
    }

    /deep/ .van-field__control {
      font-size: 14px !important;
      padding-left: 0;
      line-height: 30px;

      &::placeholder {
        font-size: 14px;
        color: #999;
      }
    }

    .arrow {
      width: calc(100% - 100px - 209px);

      img {
        width: 6px;
        height: 12px;
        margin-top: 19px;
        float: right;
      }
    }
  }
}

/deep/ .van-field__control {
  font-size: 30px !important;
  padding-left: 40px;

  &::placeholder {
    font-size: 16px;
    color: #999;
  }
}

.bottom {
  width: 100%;
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;

  .btn {
    width: calc((100% - 20px - 20px - 20px) / 2);
  }

  .l_btn {
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
