import Router from '@/router/index'

function back() {
  if (window.sessionStorage.firstUrl === window.location.href) {
    Router.replace({ path: '/' })
  } else {
    Router.go(-1);
  }
}

export default {
  back
}